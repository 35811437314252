<template>
  <create-discrepancy-report
    :cash-account-id="pscId"
    :is-submitting="isSubmitting"
    :submission-error="error"
    :discrepancy-options="natureOfDiscrepancyTypes"
    @submit="submit"
    @store-offline="storeOffline"
    @back="$router.push({ name: 'PSC Main Page' })"
  />
</template>

<script>
import { mapGetters } from "vuex";
import { createDiscrepancyReport } from "@/services/cashManagement.service";
import CreateDiscrepancyReport from "../../_CreateDiscrepancyReport.vue";
import { discrepancyNature } from "@/constants/APIEnums/cashManagementEnums";

export default {
  name: "CreateManualRecord",
  components: {
    CreateDiscrepancyReport,
  },
  data() {
    return {
      isSubmitting: false,
      error: null,
    };
  },
  computed: {
    ...mapGetters({
      pscId: "cashManagement/stationCashAccounts/pscId",
    }),
    natureOfDiscrepancyTypes() {
      return [
        {
          value: discrepancyNature.PSM_MANUAL_RECORD.VALUE,
          label: "PSM Manual Record",
          description: "",
        },
        {
          value: discrepancyNature.CASH_FLOAT.VALUE,
          label: "Manual Change e-Log",
          description: "",
        },
        {
          value: discrepancyNature.PRE_ENCODED_TICKET.VALUE,
          label: "Pre-encoded Ticket e-log.",
          description: "",
        },
        {
          value: discrepancyNature.CASH_DECLARATION_PSC.VALUE,
          label: "Cash Declaration",
        },
        {
          value: discrepancyNature.OTHERS.VALUE,
          label: "Others",
          description: "",
        },
      ];
    },
  },
  methods: {
    async submit(submissionData) {
      this.isSubmitting = true;
      const result = await createDiscrepancyReport(submissionData);
      this.isSubmitting = false;
      this.showModal = false;
      if (result.success) {
        this.$router.push({ name: "PSC Main Page" });
      } else {
        this.error = result.payload;
        this.$scrollTop();
      }
    },
    storeOffline(submissionData) {
      this.$store.dispatch(
        "offlineData/createPSMDiscrepancy",
        submissionData
      );
      this.$router.push({ name: "PSC Main Page" });
    }
  },
};
</script>
